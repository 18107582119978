import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
//import Commitment from '@widgets/Commitment'
import NewsletterCompact from '@widgets/NewsletterCompact'

export default () => (
  <>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader='If you need our help, have questions about how to use the site or are experiencing any technical difficulties, please do not hesitate to contact us.'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        {/*<Commitment />*/}
        <Divider />
        <ContactInfo />
        <Divider />
        <NewsletterCompact />
      </Sidebar>
    </Stack>
  </>
)
